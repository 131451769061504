/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
// @import "~@ng-select/ng-select/themes/material.theme.css";
 
body{ height:100%;overflow: auto;

}
html{ height: 100%;}
.text-fix {
    font-size: 12px;
}
.text-fix-14 {
  font-size: 14px;
}
.pointer {
    cursor: pointer;
}

.main{
    // background-color: #FF3CAC;
    // background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);
background: #ad5389;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #3c1053, #ad5389);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #3c1053, #ad5389); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
height: 100vh;
overflow: hidden; 
}


.light-header {
    background-color:rgba(255, 255, 255, 0.797);
}


// .top-searchbar{top: 80px;}

 

// Scrool bar
::-webkit-scrollbar-track{
	// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #69418B;
}

::-webkit-scrollbar
{
	width: 5px;
	height: 5px;
	background-color: #bebebe;
}

::-webkit-scrollbar-thumb
{
	background-color: #e5cffa;

}


::-webkit-scrollbar-thumb:hover {
    background-color: #a07bbd;
}


   
         
.btn-primary {
    background-image: linear-gradient(to right, #5C258D 0%, #4389A2  51%, #5C258D  100%); 
    border: unset;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0.5px 0.5px 2px #464646;
  }

  .btn-primary:hover {
    background-position: right center; 
    color: #fff;
    text-decoration: none;
  }
 

    
 
         
.btn-success {
    background-image: linear-gradient(to right, #02AAB0 0%, #00CDAC  51%, #02AAB0  100%); 
    border: unset;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0.5px 0.5px 2px #464646;
  }

  .btn-success:hover {
    background-position: right center;  
    color: #fff;
    text-decoration: none;
  }
 

           
  .btn-warning {
    background-image: linear-gradient(to right, #FF8008 0%, #FFC837  51%, #FF8008  100%);
    border: unset;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0.5px 0.5px 2px #464646;
  }

  .btn-warning:hover {
    background-position: right center; 
    color: #fff;
    text-decoration: none;
  }
 


           
  .btn-secondary {
    background-image: linear-gradient(to right, #283048 0%, #859398  51%, #283048  100%);
    border: unset;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0.5px 0.5px 2px #464646;
  }

  .btn-secondary:hover {
    background-position: right center; 
    color: #fff;
    text-decoration: none;
  }
 

  .text-primary {
    color: #180F53 !important;
  }


  
.ng-select.small-ng-select {
    font-size: 12px;
}
.ng-select.small-ng-select .ng-select-container  {            
    min-height: 30px!important;
}



.cursor-pointer {
  cursor: pointer;
}

.action-btn {
  color: #140e3d !important;
   
  }
  
.action-btn:hover {
  color: #ee0808 !important;
  }